export function loadFonts() {
  const WebFontLoader = require('webfontloader');

  WebFontLoader.load({
    google: {
      families: [
        'Raleway:400,700&display=swap',
        'Poppins:400,500,700&display=swap',
        'Lato:400,700&display=swap'
      ],
    },
  });
}
