<template>
  <v-container class="fill-height background-image">
    <v-row justify="center" align="center">
      <v-col cols="12" sm="10" md="8">
        <v-card class="pa-4 card-shadow animated-card">
          <v-row>
            <v-col cols="12" md="6" class="info-column">
              <v-card-title class="text-center title-font">
                <h2 class="text-h5">Bóveda Digital Documental</h2>
              </v-card-title>
              <v-card-subtitle class="text-center subtitle-font">
                <p class="mb-2">
                  Bienvenido a la Bóveda Digital Documental del Gobierno del Estado.
                </p>
                <p class="mb-2 text-font">
                  Accede a documentos importantes de manera segura y eficiente.<br>
                  Nuestra plataforma ofrece una solución integral para la gestión<br>
                  y almacenamiento de documentos digitales, garantizando la<br>
                  confidencialidad y disponibilidad de la información.
                </p>
                <p class="mb-4 text-font">
                  Si ya tienes una cuenta, por favor, inicia sesión. Si no, contacta<br>
                  al administrador para obtener acceso.
                </p>
              </v-card-subtitle>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                <v-form @submit.prevent="login">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        label="Correo electrónico"
                        v-model="email"
                        type="email"
                        prepend-inner-icon="mdi-email"
                        required
                      ></v-text-field>
                    </template>
                    <span>Introduce tu correo electrónico</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        label="Contraseña"
                        v-model="password"
                        type="password"
                        prepend-inner-icon="mdi-lock"
                        required
                      ></v-text-field>
                    </template>
                    <span>Introduce tu contraseña</span>
                  </v-tooltip>
                  <v-btn type="submit" color="primary" class="mt-4" :loading="loading">
                    <v-icon left>mdi-login</v-icon> Ingresar
                  </v-btn>
                </v-form>
                <v-progress-linear v-if="loading" indeterminate color="primary" class="mb-4"></v-progress-linear>
                <v-alert v-if="error" :type="errorType" dismissible class="mt-4">{{ error }}</v-alert>
                <v-snackbar v-model="snackbar" :timeout="timeout" top>
                  {{ snackbarMessage }}
                </v-snackbar>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
  data() {
    return {
      email: '',
      password: '',
      error: '',
      errorType: 'error',
      loading: false,
      snackbar: false,
      snackbarMessage: '',
      timeout: 3000
    };
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    login() {
      this.loading = true;
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/login.php`, {
        email: this.email,
        password: this.password
      })
      .then(response => {
        this.loading = false;
        if (response.data.status === 'success') {
          const user = response.data.user;
          localStorage.setItem('user', JSON.stringify(user)); // Guardar los datos del usuario en el Local Storage
          this.$router.push(response.data.redirect); // Redirigir basado en el perfil del usuario
        } else if (response.data.status === 'error') {
          switch (response.data.message) {
            case 'Usuario no encontrado':
              this.error = 'Usuario no encontrado. Por favor, verifica tu correo electrónico.';
              this.errorType = 'warning';
              break;
            case 'Contraseña incorrecta':
              this.error = 'Contraseña incorrecta. Por favor, intenta de nuevo.';
              this.errorType = 'error';
              break;
            case 'Error en el servidor':
              this.error = 'Por favor, ingresa tus credenciales de acceso.';
              this.errorType = 'error';
              break;
            default:
              this.error = 'Error en la autenticación';
              this.errorType = 'error';
              break;
          }
        }
      })
      .catch(error => {
        this.loading = false;
        console.error('There was an error!', error);
        this.error = 'Error en la autenticación. Por favor, inténtalo de nuevo más tarde.';
        this.errorType = 'error';
        this.snackbar = true;
        this.snackbarMessage = 'Error en la autenticación';
      });
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Poppins:wght@400;500;700&family=Lato:wght@400;700&display=swap');

.fill-height {
  height: 100vh;
}

.background-image {
  background-image: url('@/assets/back.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 16px;
}

.text-center {
  text-align: center;
}

.info-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: normal;
  padding-right: 16px; /* Añade un padding para separar las columnas */
  box-sizing: border-box; /* Asegúrate de que el padding no afecte el ancho de la columna */
}

.card-shadow {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2); /* Sombra más uniforme y marcada */
  border-radius: 8px; /* Añade bordes redondeados para un mejor aspecto */
  transition: transform 0.3s ease-in-out;
}

.animated-card:hover {
  transform: scale(1.05);
}

.title-font {
  font-family: 'Raleway', sans-serif;
  color: #691B32; /* Aplicar color #691B32 al título */
}

.subtitle-font {
  font-family: 'Poppins', sans-serif;
}

.text-font {
  font-family: 'Poppins', sans-serif;
}
</style>
