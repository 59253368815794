<template>
  <v-container class="fixed-height">
    
    <v-row justify="center" align="start">
      <v-col cols="12" sm="10" md="8">
        <!-- Card para el título, imagen y botón de cerrar sesión -->
        <v-card class="pa-4 mb-4 card-shadow fixed-card">
          <v-card-title>
            <v-row align="center" justify="space-between">
              <v-col cols="3">
                <v-img src="../assets/hidalgo.png" contain></v-img>
              </v-col>
              <v-col cols="6">
                <h5>Bóveda Digital Documental del Gobierno del Estado</h5>
              </v-col>
              <v-col cols="3" class="text-right">
                <v-btn icon color="primary" @click="logout">
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-subtitle class="pa-4">
            <v-row justify="space-between" align="center">
              <v-col cols="12" class="d-flex align-center">
                <v-icon class="mr-1" color="secondary">mdi-account</v-icon>
                <span v-if="usuario && usuario.nombre">{{ usuario.nombre }}</span>
                <span v-else>Usuario</span>
                <v-chip class="ml-2" color="green" small>En sesión</v-chip>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="selectedFolder"
                  :items="folders"
                  label="Selecciona un año"
                  class="mb-4"
                  @change="clearSearchAndResults"
                ></v-select>
                <v-text-field
                  v-model="searchQuery"
                  label="Buscar nombre del documento"
                  @input="fetchPDFs"
                  class="mb-4"
                  :disabled="!selectedFolder"
                ></v-text-field>
                <v-alert v-if="showAlert" type="info" class="mb-4">
                  Se encontraron {{ pdfs.length }} coincidencias.
                </v-alert>
                <v-list v-if="pdfs.length > 0">
                  <v-list-item
                    v-for="pdf in pdfs"
                    :key="pdf.path"
                    @click="openPDF(pdf.path)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ pdf.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="6">
                <v-card v-if="selectedPdf" class="pa-4">
                  <v-alert v-if="emailSuccessMessage" type="success" class="mb-4">
                    {{ emailSuccessMessage }}
                  </v-alert>
                  <v-alert v-if="emailErrorMessage" type="error" class="mb-4">
                    {{ emailErrorMessage }}
                  </v-alert>
                                    
                  <v-card-title class="text-h6">{{ selectedPdfTitle }}</v-card-title>
                  <v-card-subtitle>{{ selectedPdfDisplayPath }}</v-card-subtitle>
                  <iframe
                    v-if="selectedPdf"
                    :src="selectedPdfPath"
                    style="width: 100%; height: 500px;"
                    frameborder="0"
                  ></iframe>
                  
                  <v-text-field
                    v-model="email"
                    label="Correo electrónico"
                    class="mt-2"
                  ></v-text-field>
                  <v-btn color="primary" @click="sendEmail" class="mt-2">
                    Enviar por correo
                  </v-btn>


                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
  name: 'UserDashboard',
  data() {
    return {
      usuario: null,
      folders: [],
      selectedFolder: null,
      searchQuery: '',
      pdfs: [],
      selectedPdf: null,
      showAlert: false,
      email: '', // Campo para el correo electrónico
      emailSuccessMessage: '',
      emailErrorMessage: ''
    };
  },
  created() {
    const user = localStorage.getItem('user');
    if (user) {
      this.usuario = JSON.parse(user);
    }
    this.fetchFolders();
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    logout() {
      localStorage.removeItem('user');
      this.router.push('/');
    },
    async fetchFolders() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/list_folders.php`);
        if (response.data.status === 'success') {
          this.folders = response.data.folders.includes('doctos') ? response.data.folders : ['doctos', ...response.data.folders];
        } else {
          console.error('Error al obtener las carpetas:', response.data.message);
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    },
    async fetchPDFs() {
      this.showAlert = false; // Hide alert before search
      if (!this.searchQuery) {
        this.clearSearchAndResults();
        return;
      }
      if (!this.selectedFolder) {
        return;
      }
      try {
        const folder = this.selectedFolder;
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/list_pdfs.php`, {
          params: {
            query: this.searchQuery,
            folder: folder
          }
        });
        if (response.data.status === 'success') {
          this.pdfs = response.data.pdfs;
          this.showAlert = true; // Show alert after search
        } else {
          console.error('Error al obtener los PDFs:', response.data.message);
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    },
    clearSearchAndResults() {
      this.searchQuery = '';
      this.pdfs = [];
      this.selectedPdf = null;
      this.showAlert = false; // Hide alert when changing folder
    },
    openPDF(path) {
      const fullPath = `${process.env.VUE_APP_API_BASE_URL.replace('/backend', '')}/${path}`;
      this.selectedPdf = { name: path.split('/').pop(), path: fullPath };
    },
    async sendEmail() {
      if (!this.selectedPdf) {
        this.emailErrorMessage = 'Por favor selecciona un PDF para enviar.';
        return;
      }
      if (!this.email) {
        this.emailErrorMessage = 'Por favor ingresa un correo electrónico.';
        return;
      }
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/send_email.php`, {
          email: this.email,
          pdfPath: this.selectedPdf.path.replace(`${process.env.VUE_APP_API_BASE_URL.replace('/backend', '')}/doctos`, '')
        });
        if (response.data.status === 'success') {
          this.emailSuccessMessage = 'Correo enviado correctamente.';
          this.emailErrorMessage = '';
        } else {
          this.emailErrorMessage = `Error al enviar el correo: ${response.data.message}`;
          this.emailSuccessMessage = '';
        }
      } catch (error) {
        console.error('There was an error!', error);
        this.emailErrorMessage = 'Error al enviar el correo.';
        this.emailSuccessMessage = '';
      }
    }
  },
  computed: {
    selectedPdfPath() {
      return this.selectedPdf ? this.selectedPdf.path : '';
    },
    selectedPdfTitle() {
      return this.selectedPdf ? this.selectedPdf.name.replace('.pdf', '') : '';
    },
    selectedPdfDisplayPath() {
      return this.selectedPdf ? `/${this.selectedPdf.path.split('/doctos/')[1]}` : '';
    }
  },
  watch: {
    selectedFolder() {
      this.clearSearchAndResults();
    }
  }
};
</script>

<style>


.fill-height {
  height: 100vh;
}
.fixed-card {
  height: 100%;
}
.card-shadow {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2); /* Sombra más uniforme y marcada */
  border-radius: 8px; /* Añade bordes redondeados para un mejor aspecto */
}
.mb-4 {
  margin-bottom: 16px;
}
.text-h5 {
  font-size: 1.25rem;
  font-weight: 500;
}
.text-right {
  text-align: right;
}
</style>
