<template>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-card>
            <v-card-title class="d-flex justify-center">
              <h1>Registrar Usuario</h1>
            </v-card-title>
            <v-card-text>
              <v-form @submit.prevent="register">
                <v-text-field
                  label="Nombre"
                  v-model="nombre"
                  prepend-inner-icon="mdi-account"
                  required
                ></v-text-field>
                <v-text-field
                  label="Email"
                  v-model="email"
                  type="email"
                  prepend-inner-icon="mdi-email"
                  required
                ></v-text-field>
                <v-text-field
                  label="Contraseña"
                  v-model="password"
                  type="password"
                  prepend-inner-icon="mdi-lock"
                  required
                ></v-text-field>
                <v-select
                  label="Perfil"
                  v-model="perfil"
                  :items="['admin', 'usuario']"
                  required
                ></v-select>
                <v-btn type="submit" color="primary" class="mt-4">Registrar</v-btn>
              </v-form>
              <v-alert v-if="error" type="error" dismissible class="mt-4">{{ error }}</v-alert>
              <v-alert v-if="success" type="success" dismissible class="mt-4">{{ success }}</v-alert>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  import { useRouter } from 'vue-router';
  
  export default {
    data() {
      return {
        nombre: '',
        email: '',
        password: '',
        perfil: '',
        error: '',
        success: ''
      };
    },
    setup() {
      const router = useRouter();
      return { router };
    },
    methods: {
      register() {
        axios.post(`${process.env.VUE_APP_API_BASE_URL}/register.php`, {
          nombre: this.nombre,
          email: this.email,
          password: this.password,
          perfil: this.perfil
        })
        .then(response => {
          if (response.data.status === 'success') {
            this.success = response.data.message;
            this.error = '';
            this.nombre = '';
            this.email = '';
            this.password = '';
            this.perfil = '';
          } else {
            this.error = response.data.message;
            this.success = '';
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
          this.error = 'Error en el registro';
          this.success = '';
        });
      }
    }
  };
  </script>
  