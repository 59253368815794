import { createRouter, createWebHistory } from 'vue-router';
import Login from './components/LoginUsuario.vue';
import RegisterUser from './components/RegisterUser.vue';
import UserDashboard from './components/UserDashboard.vue';

const routes = [
  { path: '/', component: Login },
  { path: '/register', component: RegisterUser },
  { path: '/dashboard', component: UserDashboard }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;


